.bg-primary {
    background-color: #007bff!important;
}
.text-primary {
    color: #007bff!important;
}

a {
    color: #868e96;
    
}

a:hover, a:focus, a:active {
    color: #8eb4db;
  }
  
  .social-icons a:hover {
    background-color:  #8eb4db;
  }
  
  .dev-icons {
    font-size: 3rem;
  }
  
  .dev-icons .list-inline-item i:hover {
    color:  #007bff;
  }

  .img-val {
    width: 200px;
    height: 200px;
  }

  .d-ruby {
    display: ruby;
  }
.mb-30 {
  margin-bottom: 30px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.linea {
  border-top-style: solid;
  border-top-color: lightgray;
  border-top-width: 1px;
  padding-top: 10px;
}

.img-sinval {
  width: 30%;
  height: auto
}

.bg-grey{
  background-color: #e0e0e0;
}



.div-login{
  display: flex;
  align-content: center;
  width: auto;
  margin: auto;
  align-items: center;
  text-align: left;
}

.h5{
  align-items: center;
  margin-bottom: 0 !important;
}

.color-nav{
background: #212529 !important;
}